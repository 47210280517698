import React from "react";
import { cn } from "~/utils/cn";
import { CheckMarkIcon } from "./icons/CheckMarkIcon";
import { Paragraph } from "./primitives/text/Paragraph";

export function CheckedBulletItem({
  text,
  iconColor = "text-apple-500",
  className,
  bgColor,
  ariaLabel,
}: {
  text: React.ReactNode;
  iconColor?: string;
  bgColor?: string;
  className?: string;
  ariaLabel?: string;
}) {
  return (
    <li
      className="-ml-1 flex items-center gap-x-1 sm:gap-x-1.5"
      aria-label={ariaLabel}
    >
      <div className="relative items-center justify-center">
        {bgColor && (
          <div
            className={cn(
              "full absolute left-1/2 top-1/2 -z-10 h-3 w-3 -translate-x-1/2 -translate-y-1/2 transform rounded-full p-0.5",
              bgColor
            )}
          />
        )}
        <CheckMarkIcon
          className={cn("z-10 flex size-5", iconColor, className)}
        />
      </div>
      <Paragraph variant="large" className="text-charcoal-300">
        {text}
      </Paragraph>
    </li>
  );
}
